import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
@Component({
  selector: 'itl-loading-indicator',
  template: `<ion-spinner name="lines"></ion-spinner>`,
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class LoadingIndicatorComponent {}
