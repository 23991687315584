import { Component, Input, OnChanges } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { ColorService } from '@services/color.service';
import { AlbertaIconModule } from '../../alberta-icon/alberta-icon.module';
import { InfoModalComponent } from './../info-modal/info-modal.component';

export enum LabelType {
  Custom,
  Information,
  Warning,
  Note,
}

@Component({
  selector: 'itl-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  standalone: true,
  imports: [CommonModule, AlbertaIconModule, IonicModule],
})
export class LabelComponent implements OnChanges {
  @Input() type: LabelType = LabelType.Custom;
  @Input() baseColor: string;
  @Input() showInfoBox = false;
  @Input() infoHeader: string;
  @Input() infoMessage: string;
  @Input() labelStyle: string;
  @Input() icon: string;
  @Input() noBorder = false;
  public labelColor: string;
  public LabelType = LabelType;
  public infoStyle: string;

  constructor(private _modalController: ModalController) {}

  ngOnChanges(): void {
    if (this.type === LabelType.Information) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-info').trim();
    } else if (this.type === LabelType.Warning) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-warning').trim();
    } else if (this.type === LabelType.Note) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-note').trim();
    }
    this.setLabelColor();
  }

  public async showInfoMessage() {
    if (!this.showInfoBox) {
      return;
    }
    const infoModal = await this._modalController.create({
      component: InfoModalComponent,
      componentProps: {
        baseColor: this.baseColor.trim(),
        message: this.infoMessage,
        header: this.infoHeader,
      },
      cssClass: 'auto-height',
      backdropDismiss: true,
    });
    infoModal.present();
  }

  private setLabelColor(): void {
    if (!this.baseColor) {
      return;
    }
    const hsl = ColorService.hexToHSL(this.baseColor.trim());
    const hslPrefix = 'hsl(' + hsl.h + ',' + hsl.s * 100 + '%,';
    this.infoStyle = `color: ${hslPrefix}${hsl.l * 100}%); z-index: 10`;
    this.labelColor = `
      background-color: ${hslPrefix}95%);
      border-color: ${hslPrefix}70%);
      color: ${hslPrefix}${hsl.l * 100}%);
    `;
  }
}
